<template lang="pug">
  .main
    div(v-if="!pageIsLoading")
      .cursor(
        :style="mouseCoordinates"
      )
      img.img-blended(src="../assets/Noise.jpg" :style="imagePosition")
      img.img__sign(src="../assets/Sign.png")
      .text-block
        p.subtitle NEW CHAPTER
        p.title SPRINGSTORIES
      
      p.social__spotify(@click="clickOnSpotify") spotify
      p.social__instagram(@click="clickOnInstagram") instagram
      p.social__youtube(@click="clickOnYoutube") youtube
      p.social__facebook(@click="clickOnFacebook") facebook
    .loading(v-if="pageIsLoading")
      loading-stretch.loading__stretch(
        background="rgba(0,0,0,0.5)"
      )
</template>

<script>
import { Stretch } from 'vue-loading-spinner'
export default {
  name: "LandingPage",
  components: {
    "loading-stretch": Stretch
  },
  data: () => ({
    mouseCoordinates: {
      top: "50vh",
      left: "50vw"
    },
    imagePosition: {
      top: 0,
      left: 0
    },
    pageIsLoading: true
  }),
  methods: {
    mouseIsMoving({ clientX, clientY }) {
      if (window.innerWidth >= 600) {
        this.mouseCoordinates.top = clientY + "px"
        this.mouseCoordinates.left = clientX + "px"
        this.imagePosition.top = (window.innerHeight / 2 - clientY) * 0.05 + "px"
        this.imagePosition.left = (window.innerWidth / 2 - clientX) * 0.05 + "px"
      } else {
        this.mouseCoordinates.top = "50vh"
        this.mouseCoordinates.left = "50vw"
        this.imagePosition.top = 0
        this.imagePosition.left = 0
      }
    },
    clickOnSpotify() {
      window.location.href =
        "https://open.spotify.com/artist/5yh5LODm3XkIksXOrboRgC?si=7ruTibQCQCWI369R63_HqQ"
    },
    clickOnInstagram() {
      window.location.href = "http://instagram.com/springstories_official"
    },
    clickOnYoutube() {
      window.location.href = "https://www.youtube.com/watch?v=vrpscFgayKs"
    },
    clickOnFacebook() {
      window.location.href = "https://www.facebook.com/SpringstoriesOfficial/"
    }
  },
  async mounted() {
    window.addEventListener("pointermove", this.mouseIsMoving)
    window.addEventListener("DOMContentLoaded", () => {
      this.pageIsLoading = false
      window.removeEventListener("DOMContentLoaded")
    })
  },
  destroyed: function () {
    window.removeEventListener("pointermove", this.mouseIsMoving)
  }
}
</script>

<style lang="sass">
p
  @media screen and (max-width: 600px)
    font-size: 0.8rem

.main
  cursor: none
  width: 100vw
  height: 100vh
  background: rgb(39,129,118)
  background: linear-gradient(45deg, rgba(39,129,118,1) 0%, rgba(156,219,211,1) 100%)
  @media screen and (max-width: 600px)
    cursor: auto


  .cursor
    pointer-events: none
    position: absolute
    width: 20px
    height: 20px
    border-radius: 30vw
    transform: translate(-50%, -50%)
    background: rgba(255, 255, 255, 0.4)
    z-index: 1000
    @media screen and (max-width: 600px)
      display: none

  @keyframes background-image
    from
      transform: translate(calc(-5% - 0.5px), calc(-5% - 0.5px))
    to
      transform: translate(calc(-5% + 0.5px), calc(-5% + 0.5px))

  .img-blended
    position: absolute
    mix-blend-mode: overlay
    max-width: 110%
    min-height: 110%
    transform: translate(-5%, -5%)
    animation-name: background-image
    animation-duration: 0.1s
    animation-direction: alternate
    animation-iteration-count: infinite
    @media screen and (max-width: 600px)
      left: 0
      top: 0

    @keyframes sign
      from
        width: 30vw
      to
        width: 31vw

  .img__sign
    position: absolute
    transform: translate(-50%, -50%)
    top: 45%
    left: 50%
    animation-name: sign
    animation-duration: 1s
    animation-direction: alternate
    animation-iteration-count: infinite
    animation-timing-function: ease-in-out

  .text-block
    position: absolute
    top: 85%
    left: 50%
    width: 100%
    transform: translate(-50%, -50%)
    font-size: 1.5rem

    .title
      font-weight: 0
      margin-top: 0
      @media screen and (max-width: 600px)
        font-size: 1rem

    .subtitle
      font-weight: 1000
      margin-bottom: 0rem
      letter-spacing: 0.1rem
      @media screen and (max-width: 600px)
        font-size: 1rem

  @keyframes social-selector
    from
      background-color: rgba(0,0,0,0)
      text-decoration: none
    to
      background-color: rgba(0,0,0,1)
      text-decoration: underline

  .social
    &__instagram
      position: absolute
      top: 2vh
      left: 2vw
      &:hover
        color: white
        transition: 1s

    &__spotify
      position: absolute
      top: 2vh
      right: 2vw
      &:hover
        color: white
        transition: 1s

    &__youtube
      position: absolute
      bottom: 2vh
      left: 2vw
      &:hover
        color: white
        transition: 1s

    &__facebook
      position: absolute
      bottom: 2vh
      right: 2vw
      &:hover
        color: white
        transition: 1s

  .loading
    width: 100vw
    height: 100vh
    background: rgb(39,129,118)
    background: linear-gradient(45deg, rgba(39,129,118,1) 0%, rgba(156,219,211,1) 100%)
    &__stretch
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
</style>
