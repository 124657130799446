<template lang="pug">
  #app
    landing-page
</template>

<script>
import LandingPage from "@/views/LandingPage"

export default {
  name: "App",
  components: {
    "landing-page": LandingPage
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap')

html, body
  margin: 0
  overflow: hidden
  padding: 0

#app
  font-family: Work Sans, Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  background-color: black
</style>
